<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-pagamenti-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Pagamenti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <b-card :border-variant="border_color">
          <b-card-header class="pt-0">
              <b-card-title :class="title_color">
                Licenza n. {{ dati.license.ref_code }}
              </b-card-title>
          </b-card-header>

          <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Pagamento e Licenza</span>
                  </template>
                  <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                            <dl>
                                <dt class="text-muted">
                                    Stato
                                </dt>
                                <dd>
                                    <strong v-if="dati.paid" class="text-primary">Pagato</strong>
                                    <strong v-else class="text-warning">Da pagare</strong>
                                </dd>
                            </dl>
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Importo" :field_value="prezzoFormattato(dati.license.price)" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Licenza" :field_value="dati.license.ref_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Tipologia" :field_value="dati.license.type" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Creato il" :field_value="formattazioneDataOra(dati.created_at, 'it')" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Aggiornato al" :field_value="formattazioneDataOra(dati.updated_at, 'it')" />
                        </b-col>
                      </b-row>
                      
                      <div v-if="dati.paid === false" class="pb-2">
                        <hr />
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary"
                            class="mb-1"
                            @click="vaiAlPagamento(dati.license.id)"
                        >
                            Effettua il pagamento
                        </b-button>
                      </div>

                    </b-card>
                  </b-tab>

                  <b-tab v-if="dati.dispute">
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Disputa</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col cols="12">
                          <showdata field_name="Stato:" :field_value="dati.dispute.dispute_type.name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Creato il" :field_value="formattazioneDataOra(dati.dispute.api_event_created_at, 'it')" />
                        </b-col>
                        <b-col md="6" lg="4">
                          <showdata field_name="payment_intent" :field_value="dati.dispute.api_payment_intent" />
                        </b-col>
                        
                      </b-row>
                    </b-card>
                  </b-tab>

                  <b-tab v-if="dati.refund !== null">
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Rimborso</span>
                    </template>
                    <b-card bg-variant="Default">
                        <showdata field_name="Stato del rimborso" :field_value="dati.refund" />
                    </b-card>
                  </b-tab>

              </b-tabs>
          </b-card-body>
        </b-card>
    </div>
      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, VBToggle, BBadge, VBTooltip, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { logoutGeneral } from "@core/components/logout/logout.vue"

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BButton, 
    BBadge,
    BAlert,

    showdata,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
    logoutGeneral,
  },
  setup() {
    return {
      prezzoFormattato,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      dati: { },
      Caricato: false,
      errore_caricamento: false,
      title_color: 'text-primary',
      border_color: 'primary',
      
    }
  },
  created() {
    this.caricamentoDatiPagamento(router.currentRoute.params.id_riga);

  },
  methods: {
    caricamentoDatiPagamento(id_riga_passata){
      //imposto loading della pagina
      this.Caricato = false;

      this.$http.get('api/customer/license-payment-logs/card/'+id_riga_passata).then(response => { 
        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply.licensePaymentLogData

            //console.log("FINE --------------------")
            //console.log(this.dati)

            if(this.dati.paid === true){
                this.title_color = 'text-primary';
                this.border_color = 'primary';
            } else {
                //non pagato
                this.title_color = 'text-warning';
                this.border_color = 'warning';
            }

            this.Caricato = true;
          } else {
            //accesso a pagamento che non è il tuo! => accesso "Unauthenticated" 
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });
    },
    vaiAlPagamento(id_licenza){
        this.$router.replace('/area-clienti/licenze/show/'+id_licenza)
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
  },
}
</script>